import StatusRequestNotTraitedException from "@/exceptions/status_request_not_traited_exception.js";
import TimeoutException from "@/exceptions/timeout_exception.js";
import TechnicalException from "@/exceptions/technical_exception.js";
import ResourceNotFoundException from "@/exceptions/resource_not_found_exception.js";
import UnauthorizedException from "@/exceptions/unauthorized_exception.js";
import AlreadyExistsException from "@/exceptions/already_exists_exception.js";
import MethodNotAllowedException from "@/exceptions/method_not_allowed_exception.js";
import ForbiddenException from "@/exceptions/forbidden_exception.js";
import IntegrityException from "@/exceptions/integrity_exception.js";

/**
 * donne le message générique en fonction du type de problème
 * */
export function toMessage(error) {
  if (error) {
    if (error instanceof UnauthorizedException) {
      return "une autorisation est nécessaire.";
    } else if (error instanceof TimeoutException) {
      return "l'appel au service n'a pas eu de réponse.";
    } else if (error instanceof ResourceNotFoundException) {
      return "la resource demandée n'existe pas.";
    } else if (error instanceof StatusRequestNotTraitedException) {
      return "la demande n'a pas été traitée par le service.";
    } else if (error instanceof TechnicalException) {
      return "un problème technique s'est produit durant le traitement de la requête (api).";
    } else if (error instanceof AlreadyExistsException) {
      return "cette ressource existe déjà.";
    } else if (error instanceof MethodNotAllowedException) {
      return "cet accès à la ressource n'existe pas.";
    } else if (error instanceof ForbiddenException) {
      return "votre authentification n'est pas valide.";
    } else if (error instanceof IntegrityException) {
      return "cette ressource est utilisée.";
    }
  }

  return undefined;
}
