import RequestException from "./request_exception";

export default class StatusRequestNotTraitedException extends RequestException {
  static Name() {
    return "StatusRequestNotTraitedException";
  }

  /**
   * @param {*} error  l'erreur ayant provoquée cette exception
   * @param {*} status  le status
   */
  constructor(error, status) {
    super(`le statut ${status} n'est pas géré`, error);
    this.name = StatusRequestNotTraitedException.Name();
    this.status = status;
  }
}
