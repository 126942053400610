export default class RequestException extends Error {
  static Name() {
    return "RequestException";
  }

  /**
   * @param {*} message le message du problème
   * @param {*} error  l'erreur ayant provoquée cette exception
   */
  constructor(message, error) {
    super(message);
    this.name = RequestException.Name();
    this.error = error;
  }
}
