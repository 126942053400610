import RequestException from "./request_exception";

export default class TimeoutException extends RequestException {
  static Name() {
    return "TimeoutException";
  }

  constructor(error) {
    super("la requête a expirée", error);
    this.name = TimeoutException.Name();
  }
}
