import RequestException from "./request_exception";

export default class ResourceNotFoundException extends RequestException {
  static Name() {
    return "ResourceNotFoundException";
  }

  /**
   * @param {*} error  l'erreur ayant provoquée cette exception
   * @param {*} resourceId  identifiant de la ressource
   */
  constructor(error, resourceId) {
    super(`la ressource ${resourceId} n'existe pas.`, error);
    this.name = ResourceNotFoundException.Name();
    this.resourceId = resourceId;
  }
}
