import RequestException from "./request_exception";

export default class MethodNotAllowedException extends RequestException {
  static Name() {
    return "MethodNotAllowedException";
  }

  /**
   * @param {*} error  l'erreur ayant provoquée cette exception
   * @param {*} message  le message
   */
  constructor(error, message) {
    super(message, error);
    this.name = MethodNotAllowedException.Name();
  }
}
