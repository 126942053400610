var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mb-6", attrs: { justify: "center" } },
    [
      _vm.backbutton
        ? _c(
            "v-btn",
            {
              attrs: { icon: "", large: "" },
              on: {
                click: function ($event) {
                  return _vm.back()
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: "secondary" } }, [
                _vm._v("mdi-arrow-left"),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.backbutton
        ? _c("div", { staticStyle: { width: "40px" } })
        : _vm._e(),
      _c("v-spacer"),
      _c("div", { staticClass: "d-flex justify-center main-title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("v-spacer"),
      _c("div", { staticStyle: { width: "40px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }