<template>
  <v-row justify="center" class="mb-6">
    <v-btn icon large @click="back()" v-if="backbutton">
      <v-icon color="secondary">mdi-arrow-left</v-icon>
    </v-btn>
    <div v-if="!backbutton" style="width: 40px"></div>
    <v-spacer></v-spacer>
    <div class="d-flex justify-center main-title">{{ title }}</div>    
    <v-spacer></v-spacer>
    <div style="width: 40px"></div>
  </v-row>
</template>

<script>
export default {
  name: "TitleAndreturnComponent",

  mixins: [],
  props: {
    /**le titre de la vue. */
    title: {
      type: String,
      required: true,
    },
    /**Est-ce que on affiche le bouton 'retour'? */
    backbutton: {
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style>
</style>