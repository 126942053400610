import RequestException from "./request_exception";

export default class AlreadeyExistsException extends RequestException {
  static Name() {
    return "AlreadeyExistsException";
  }

  /**
   * @param {*} error  l'erreur ayant provoquée cette exception
   * @param {*} message  le message
   * @param {*} context  le context de l'erreur (les données en général)
   */
  constructor(error, message, context = undefined) {
    super(message, error);
    this.name = AlreadeyExistsException.Name();
    this.context = context;
  }
}
